footer {
  margin-top: auto;
  background-color: #000000;
  color: #f8f8f8;
}

/* @media only screen and (max-width: 700px) {
  footer {
    padding: 20px;
  }
} */

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-text {
  text-align: center;
  font-size: 13px;
}

@media only screen and (max-width: 500px) {
  .footer-text {
    font-size: 8px;
  }
  .footer-attribution {
    font-size: 9px;
  }
}

.footer-attribution {
  color: rgb(135, 159, 167);
  display: flex;
  align-items: center;
}

.footer-image {
  max-width: 100%;
  height: auto;
  margin-left:10px;
}

@media only screen and (max-width: 600px) {
  .footer-image {
    max-width: 80%;
    height: auto;
  }
}

#footer-link {
  color: #f8f8f8;
}
