.header-image {
  position: relative;
  left: 0;
  max-width: 100%;
  height: auto;
}

.header-logo {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  width: 50%;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  vertical-align: middle;
  content: "";
  background: 50%/100% 100% no-repeat;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

@media only screen and (max-width: 650px) {
  .navbar-brand {
    max-width: 300px;
  }
}

@media only screen and (max-width: 400px) {
  .navbar-brand {
    max-width: 250px;
  }
}

.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
