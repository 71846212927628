body {
  overflow-y: auto;
  overflow-x: hidden;
}

@media only screen and (max-width: 800px) {
  body {
    font-size: 15px;
  }
}

@media only screen and (max-width: 450px) {
  body {
    font-size: 12px;
  }
}

button {
  border-radius: 8px;
  font-size: 14px;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.info-row:nth-of-type(odd) {
  background-color: #ccc;
}

.page-title {
  display: flex;
  align-items: center;
  font-size: 30px;
  font-weight: 500;
}

@media only screen and (max-width: 800px) {
  .page-title {
    font-size: 32px;
  }
}

@media only screen and (max-width: 450px) {
  .page-title {
    font-size: 20px;
  }
}

@media only screen and (max-width: 800px) {
  .table-text {
    font-size: 13px;
  }
}

@media only screen and (max-width: 450px) {
  .table-text {
    font-size: 9px;
  }
}

.content {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.leaflet-div-icon {
  background: transparent;
  border: none;
}

#pilot-map {
  height: 30vh;
  width: 100%;
  border-radius: 6px;
  cursor: default !important;
}

#map {
  height: 40vh;
  width: 100%;
  border-radius: 6px;
  cursor: default !important;
}

#modal-map {
  height: 40vh;
  width: 100%;
  border-radius: 6px;
  cursor: default !important;
}

.map-container {
  background-color: #e4e4e4;
  /* background-color: #f9f9f9; */
  padding: 10px;
  border-radius: 6px;
}

.table-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: large;
}

.standard-container {
  margin-top: 5px;
  flex: 1 0 auto;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: stretch;
  flex-direction: column;
}

@media only screen and (min-width: 1000px) {
  .standard-container {
    padding-left: 15%;
    padding-right: 15%;
  }
}

.item {
  margin-top: 1%;
  padding-left: 8px;
  padding-right: 8px;
}

.narrow-container {
  margin-top: 5px;
  margin-left: 1%;
  margin-right: 1%;
}

@media only screen and (min-width: 500px) {
  .narrow-container {
    margin-left: 15%;
    margin-right: 15%;
  }
}

@media only screen and (min-width: 700px) {
  .narrow-container {
    margin-left: 20%;
    margin-right: 20%;
  }
}

@media only screen and (min-width: 1200px) {
  .narrow-container {
    margin-left: 37%;
    margin-right: 37%;
  }
}

.warning-text {
  font-size: 12px;
}

.text-container {
  margin-bottom: 10%;
}

@media only screen and (max-width: 400px) {
  .warning-text {
    font-size: 9px;
  }
}

.custom-error {
  color: #dc3545;
  font-size: 18px;
  font-weight: 500;
  margin-top: 1%;
}

.color-nav {
  background-color: white;
  border-bottom: solid 1px #ccc;
}

.list-button {
  background: none;
  border: none;
}

.svg-icon {
  display: inline-block;
  font-size: inherit;
  color: inherit;
  vertical-align: -0.125em;
  fill: currentColor;
  margin-left: 3vw;
}

.dropdown-item {
  padding: 0 0.5rem;
}

.MuiDataGrid-iconSeparator {
  visibility: hidden;
}

.MuiDataGrid-columnHeader,
.MuiDataGrid-cell {
  padding: 3px !important;
}

.MuiDataGrid-columnHeaderTitleContainer {
  padding: 0 !important;
}

.MuiDataGrid-row {
  /* background-color: rgb(247, 247, 247); */
  font-size: 12px;
}

@media screen and (max-width: 500px) {
  .MuiDataGrid-row {
    font-size: 8px;
  }
}

.MuiDataGrid-columnHeaderTitle {
  font-size: 14px;
  font-weight: bold !important;
}

@media screen and (max-width: 500px) {
  .MuiDataGrid-columnHeaderTitle {
    font-size: 9px;
  }
}

@media screen and (max-width: 500px) {
  .MuiTab-root {
    font-size: 12px !important;
  }
}

#login-bg {
  background-image: url("./assets/iStock-638722382_XL.jpg");
  position: top right;
  background-size: cover;
}
